<template>
  <v-main>
    <div class="error text-center white--text title" v-if="serverError">{{serverError}}</div>
    <v-container fluid class="fill-height">
      <v-row justify="center" align="center">
        <v-col cols="3">
          <v-card>
            <v-card-title class="info white--text title justify-center primary">Подтверждение акаунта</v-card-title>
            <v-card-text class="text-center pa-4">
              <v-progress-circular
              size="70"
              width="7"
              color="blue"
              indeterminate
              v-if="showProgress"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  data: () => ({
    serverError: '',
    showProgress: true
  }),
  mounted: function() {
    this.$axios.post('user/account/email-confirm', { token: this.$route.query.token }).then(() => {
      this.$router.push('/login')
    }).catch(err => {
      if(err.response.data.error) {
        this.serverError = err.response.data.error
      } else {
        this.serverError = 'Не удается соединиться с сервером'
      }
    }).finally(() => {
      this.showProgress = false
    })
  }
}
</script>
